export const getTextWB = (hexColor: string) => {
  let _lumina = getLumina(hexColor)
  if (_lumina < 128) {
    return '#ffffff'
  }
  return '#000000'
}

export const getLumina = (hexColor: string) => {
  if (hexColor && hexColor.startsWith('#')) {
    var c = hexColor.substring(1) // strip #
    var rgb = parseInt(c, 16) // convert rrggbb to decimal
    var r = (rgb >> 16) & 0xff // extract red
    var g = (rgb >> 8) & 0xff // extract green
    var b = (rgb >> 0) & 0xff // extract blue
    var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b // per ITU-R BT.709
    return luma
  }
  return 0
}

export const nameOfColorsBootstrap = [
  'primary',
  'danger',
  'success',
  'secondary',
  'warning'
]

export const validColorBootstrap = (color: string) => {
  if (color && nameOfColorsBootstrap.indexOf(color) > -1) {
    return true
  }
  return false
}

export const randomHextColor = () => {
  return '#' + Math.floor(Math.random() * 16777215).toString(16)
}
