import * as React from 'react'
import MyComponentTypes from '../MyComponent/Types'
const MyLoadingHelpers = {
  addTypes(key: string, fnUI: any) {
    MyComponentTypes.addTypesGeneric(MyLoadingHelpers.Types, key, fnUI)
  },
  Types: {
    _empty: MyComponentTypes.Types._empty,
    _default: (props: any) => {
      return (
        <i
          key='iconloading'
          className={`fa fa-spinner fa-spin ${
            props.className != null ? props.className : ''
          }`}
          style={props.style}
        />
      )
    },
    _defaultlazy: (props: any) => {
      return (
        <div>
          <i
            key='iconloading'
            className={`fa fa-spinner fa-spin ${
              props.className != null ? props.className : ''
            }`}
            style={props.style}
          />
        </div>
      )
    }
  }
}

export default MyLoadingHelpers
