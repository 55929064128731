import LodashGet from 'lodash/get'
import LodashSet from 'lodash/set'
import LodashHas from 'lodash/has'
import {
  isFunction,
  isJsonString,
  isNullOrEmpty,
  isObject,
  isArray,
  isString
} from './Is'
import { json2Obj } from './Json'
import { logOne } from './Log'
import { RGSquareBracketReplace } from './Regex'
import HFormat from './Format'
import HComponent from './Component'
export const get = LodashGet
export const has = LodashHas
export const set = LodashSet
export const mergeObjIfExist = (srcObj: any, mergeObj: any) => {
  if (srcObj && mergeObj) {
    let _keys = Object.keys(mergeObj)
    if (_keys && _keys.length > 0) {
      for (let k of _keys) {
        srcObj[k] = mergeObj[k]
      }
    }
  }
}

export const mergeObjIfNotExist = (srcObj: any, mergeObj: any) => {
  if (srcObj && mergeObj) {
    let _keys = Object.keys(mergeObj)
    if (_keys && _keys.length > 0) {
      for (let k of _keys) {
        if (!has(srcObj, k)) {
          srcObj[k] = mergeObj[k]
        }
      }
    }
  }
}

export const runFuntion = (fnList: object, name?: string, args: any[] = []) => {
  if (fnList && name) {
    if (isFunction(fnList[name])) {
      return fnList[name].apply(null, args)
    }
  }
  logOne(`runFuntion failed: ${name}`, fnList)
}

const getArrayFieldFromSquareBracketOfText = (s: string) => {
  var _result = []
  if (s != null) {
    var _m
    while ((_m = RGSquareBracketReplace.exec(s)) !== null) {
      if (_m.length > 1) {
        _result.push(_m[1])
      }
    }
  }
  return _result
}
//Helo [name] data: {name:"Hung"} -> Helo Hung
export const getCustomData = (sourceData: any, customData: any) => {
  let _customData = {}
  if (customData) {
    let _keys = Object.keys(customData)
    for (let k of _keys) {
      let _item = customData[k]
      if (isObject(_item)) {
        if (_item.fieldName && _item.configFormat) {
          _customData[k] = HFormat.format(
            sourceData[_item.fieldName],
            _item.configFormat,
            {
              data: sourceData
            }
          )
        } else if (_item.myIcon) {
          _customData[k] = HComponent.getHtmlFromConfigComponent(_item)
        }
      } else if (isString(_item)) {
        _customData[k] = getTextWithData(_item, sourceData, {
          squareBracket: true,
          hideshowWhenNull: true
        })
      } else {
        _customData[k] = customData[k]
      }
    }
  }
  return _customData
}
export const getTextWithData = (
  text: string,
  data: any,
  opts: any = { squareBracket: true, hideshowWhenNull: true }
) => {
  if (data && text) {
    if (text.indexOf('[') > -1) {
      //co dau hieu cua squareBracket
      let _fields = getArrayFieldFromSquareBracketOfText(text)
      if (_fields && _fields.length > 0) {
        var newS = text
        for (let f of _fields) {
          let _fieldName = f
          let _value = data[_fieldName]

          if (isJsonString(f)) {
            let _obj = json2Obj(f)
            if (_obj.fieldName) {
              _fieldName = _obj.fieldName
              if (_obj.configFormat) {
                _value = HFormat.format(data[_fieldName], _obj.configFormat, {
                  data: data
                })
              }
            }
            if (!isNullOrEmpty(_value)) {
              var rg = new RegExp(`\\[${f}\\]`, 'g')
              newS = newS.replace(rg, _value)
            } else if (
              opts.hideshowWhenNull === true &&
              isNullOrEmpty(_value)
            ) {
              var rg = new RegExp(`\\[${f}\\]`, 'g')
              newS = newS.replace(rg, '')
            }
          } else if (!isNullOrEmpty(_value)) {
            var rg = new RegExp(`\\[${_fieldName}\\]`, 'g')
            newS = newS.replace(rg, _value)
          } else if (opts.hideshowWhenNull === true && isNullOrEmpty(_value)) {
            ////Bo sung hideshowWhenNull, de ko muon [] trong chuoi sau khi replace
            var rg = new RegExp(`\\[${_fieldName}\\]`, 'g')
            newS = newS.replace(rg, '')
          }
        }
        return newS
      }
    }
  }
  return text
}

// export function getFuncName() {//Not working
//   return getFuncName.caller.name
// }

export const addItemToArrayOrCreateNewArray = (currArr = [], newItem: any) => {
  let _arr: any = []
  if (currArr) {
    if (isArray(currArr)) {
      if (currArr.length > 0) {
        _arr = currArr
      }
    } else {
      _arr = [currArr]
    }
  }
  // console.warn("addItemToArrayOrCreateNewArray",currArr,newItem)
  return [..._arr, newItem]
}

export function test() {
  console.warn('test HUtils')
}

export const isLocalDev = () => {
  if (window.location.href.indexOf('localhost:') > -1) {
    return true
  }
  if (localStorage.getItem('_DEBUG_') === 'true') {
    return true
  }
  return false
}

var isMobile = {
  Android: function () {
    return navigator.userAgent.match(/Android/i)
  },
  BlackBerry: function () {
    return navigator.userAgent.match(/BlackBerry/i)
  },
  iOS: function () {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i)
  },
  Opera: function () {
    return navigator.userAgent.match(/Opera Mini/i)
  },
  Windows: function () {
    return (
      navigator.userAgent.match(/IEMobile/i) ||
      navigator.userAgent.match(/WPDesktop/i)
    )
  },
  any: function () {
    return (
      isMobile.Android() ||
      isMobile.BlackBerry() ||
      isMobile.iOS() ||
      isMobile.Opera() ||
      isMobile.Windows()
    )
  }
}
export const isRunOnAndroid = () => {
  return isMobile.Android()
}
export const isRunOniOS = () => {
  return isMobile.iOS()
}
export const isRunOnMobile = () => {
  return isMobile.any()
}

export const PrintHtml = (htmlString = '', { autoPrint = true } = {}) => {
  // window.onbeforeprint = function () {
  //   console.log('Printing prepare...')
  // }
  // window.onafterprint = function () {
  //   console.log('Printing completed...')
  // }
  let _idPrintfGloabl = 'printf_global'
  let _idPrintfGloablIframe = 'printf_global_iframe'
  let _eContain = document.getElementById(_idPrintfGloabl)
  console.log('FlowPrintHtml:', _eContain)
  if (_eContain == null) {
    const el = document.createElement('div')
    el.setAttribute('id', _idPrintfGloabl)
    el.setAttribute('style', 'display: none;')
    document.body.appendChild(el)
    _eContain = document.getElementById(_idPrintfGloabl)
  }
  if (_eContain) {
    _eContain.innerHTML = `<iframe id="printf_global_iframe" name="printf_global_iframe" scrolling='no'></iframe>`
  }
  //insert htmlString
  let _eIframe: any = document.getElementById(_idPrintfGloablIframe)
  if (_eIframe && _eIframe.contentDocument) {
    _eIframe.contentDocument.write(htmlString)
    window.frames[_idPrintfGloablIframe].focus()
    let _height = _eIframe.contentWindow.document.body.scrollHeight
    _eIframe.style.height = `${_height}px`
    if (autoPrint !== false) {
      setTimeout(() => {
        // if(document && document.body && document.body.style && document.documentElement && document.documentElement.clientWidth){
        //   document.body.style.width = `${document.documentElement.clientWidth}px`;
        // }//fix print change columns size
        if (window.frames[_idPrintfGloablIframe]) {
          if (window.frames[_idPrintfGloablIframe].document.execCommand) {
            window.frames[_idPrintfGloablIframe].document.execCommand(
              'print',
              false,
              null
            ) //chay duoc voi Coccoc
          } else {
            window.frames[_idPrintfGloablIframe].print() //sau khi gen iframe thì tự động in
          }
        } else {
          console.error('not found frames printf', window.frames)
        }
      }, 250)
    }
  }
}

export const getDataBySort = (
  data: any[],
  sortByField: string,
  sortType = 'asc',
  sortDataType = 'string'
) => {
  if (sortByField && data && data.length > 0) {
    let _sortType = sortType
    let _sortDataType = sortDataType
    if (sortType !== 'asc') {
      _sortType = 'desc'
    }
    if (sortDataType !== 'string') {
      _sortDataType = 'number'
    }
    return data.sort((a: any, b: any) => {
      if (a && b) {
        let _itemA = a[sortByField]
        let _itemB = b[sortByField]
        if (_sortDataType == 'number') {
          let _compare = _itemA - _itemB
          return _compare * (_sortType == 'desc' ? -1 : 1)
        } else {
          let _compare = 0
          if (_itemA > _itemB) {
            _compare = _sortType == 'desc' ? -1 : 1
          } else if (_itemA < _itemB) {
            _compare = _sortType == 'desc' ? 1 : -1
          }
          return _compare
        }
      }
      return 0
    })
  }
  return data
}

//merge obj2 vao obj1 deep
export const mergeRecursive = (obj1: any, obj2: any) => {
  for (var p in obj2) {
    try {
      // Property in destination object set; update its value.
      if (obj2[p].constructor == Object) {
        obj1[p] = mergeRecursive(obj1[p], obj2[p])
      } else {
        obj1[p] = obj2[p]
      }
    } catch (e) {
      // Property in destination object not set; create it and set its value.
      obj1[p] = obj2[p]
    }
  }
  return obj1
}
