import { RGHexColor } from './Regex'
export const isFunction = (v: any) => {
  return typeof v === 'function'
}
export const isArray = (v: any) => {
  return Array.isArray(v)
}
export const isString = (v: any) => {
  return typeof v === 'string'
}
export const isNumber = (v: any) => {
  return typeof v === 'number'
}
export const isBoolean = (v: any) => {
  return typeof v === 'boolean'
}
export const isJsonString = (v: any) => {
  return typeof v === 'string' && v.trim().startsWith('{')
}

// thay doi isObject, vi array cung co hasOwnProperty
// export const isObject = (v: any) => {
//   return typeof v === 'object' && v != null && v.hasOwnProperty != null
// }
export const isObject = (v: any) => {
  return (
    v != null &&
    (v.constructor.name === 'Object' || v instanceof Object) &&
    !isArray(v)
  )
}
export const isDateValue = (v: any) => {
  return new Date(v).getTime() > 0
}

export const isRegex = (v: any) => {
  return v instanceof RegExp
}

export const isHexColor = (v: any) => {
  return RGHexColor.test(v)
}

export const isNullOrEmpty = (v: any) => {
  return v === null || v === undefined || v === ''
}

export const isNotNullOrEmpty = (v: any) => {
  return !isNullOrEmpty(v)
}

export const isIsoDate = (str: string) => {
  if (
    /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z?/.test(str) ||
    /\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}:\d{2}/.test(str)
  ) {
    var d = new Date(str)
    return d.toString() !== 'Invalid Date'
  }
  return false
}
